import { Questionnaire } from '~/services/profile/questionnaireService'

export interface Answer {
  questionId: string,
  choices : string[]
}

export interface Profile {
  tenant: string
  id: number
  revision: number
  customerId: number
  questionnaireId: number
  answers: Answer[]
  createdAt: string
  updatedAt: string
}

export interface CreateProfileRequest {
  customerId: number
  questionnaireId: number
  answers: Answer[]
}

export interface UpdateProfileRequest {
  questionnaireId: number
  answers: Answer[]
}

export default {
  async createProfile(request: CreateProfileRequest): Promise<Profile> {
    return await apiPost(`profile`, { body: request })
  },
  async updateProfile(profileId: number, request: UpdateProfileRequest): Promise<Profile> {
    return await apiPut(`profile/${profileId}`, { body: request })
  },
  async updateSelfProfile(request: UpdateProfileRequest): Promise<Profile> {
    return await apiPut(`customer/self/profile`, { body: request })
  },
  async findProfileLatestRevision(): Promise<Profile> {
    return await apiGet(`customer/self/profile/latest`)
  },
  cleanUpProfile(profileAnswers: Array<Answer>, questionnaire: Questionnaire): Array<Answer> {
    const answers = [] as Array<Answer>;

    profileAnswers.forEach((answer) => {
      const question = questionnaire.questions.find((q) => q.id == answer.questionId)

      if (question) {
        const choices = answer.choices.filter((c) => question.options.some((o) => o.value == c))

        answers.push({
          questionId: answer.questionId,
          choices
        })
      }
    })

    questionnaire.questions.forEach((question) => {
      const answer = answers.find((a) => a.questionId == question.id)

      if (answer == undefined) {
        answers.push({
          questionId: question.id,
          choices:
            question.id == "desired_skincare_product" || question.id == "desired_hair_product" || question.id == "desired_makeup_product" ?
              question.options.map((o) => o.value) : []
        })
      }
    })

    return answers;
  }
}
